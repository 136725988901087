import { MenuList } from "@mgi-labs/storybook-design";

export const homeMenu: MenuList[] = [
  {
    linkleftmenu: "lien",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z"></path>
        <circle cx="18" cy="11.5" r="1"></circle>
      </svg>
    ),
    label: "Equipment",
    url: "/",
    position: "top",
  },
];
export const oneEquipmentMenu: MenuList[] = [
  {
    linkleftmenu: "lien",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
      </svg>
    ),
    label: "Estimations",
    url: "list",
    position: "top",
  },
];
export const twoOrMoreEquipmentMenu: MenuList[] = [
  {
    linkleftmenu: "lien",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z"></path>
        <circle cx="18" cy="11.5" r="1"></circle>
      </svg>
    ),
    label: "Equipment",
    url: "/",
    position: "top",
  },
  {
    linkleftmenu: "lien",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
      </svg>
    ),
    label: "Estimations",
    url: "list",
    position: "top",
  },
];
