import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style/menu.module.scss";
import { MenuList, MGILeftMenu } from "@mgi-labs/storybook-design";
import {
  homeMenu,
  oneEquipmentMenu,
  twoOrMoreEquipmentMenu,
} from "./Domain/menu-list";

import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const Menu = ({
  machineType,
  machineTypes,
  setMachineType,
}: {
  machineType: MachineModelType | undefined;
  machineTypes: MachineModelType[] | undefined;
  setMachineType: React.Dispatch<MachineModelType | undefined>;
}) => {
  const { t } = useTranslation();

  const currentMenu = !machineType
    ? homeMenu
    : machineTypes && machineTypes?.length < 2
    ? oneEquipmentMenu
    : twoOrMoreEquipmentMenu;

  const menuToDisplay: MenuList[] = useMemo(() => {
    return currentMenu.map((menu) => {
      const newMenu: MenuList = {
        linkleftmenu: menu.linkleftmenu,
        svg: menu.svg,
        label: t(menu.label),
        url:
          menu.label === "Equipment"
            ? menu.url
            : `/${machineType}/${menu.url}/`,
        position: "top",
      };
      return newMenu;
    });
  }, [currentMenu, machineType, t]);

  return (
    <>
      <div data-theme={"dark"} className={classNames(styles.menu)}>
        <MGILeftMenu
          theme={"dark"}
          project={"cost calculator"}
          linkList={menuToDisplay}
          switchTheme={false}
        ></MGILeftMenu>
      </div>
    </>
  );
};

export default Menu;
